import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesGamemodesLostPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Lost Sector</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_lost.png"
            alt="Lost Sector"
          />
        </div>
        <div className="page-details">
          <h1>Lost Sector</h1>
          <h2>Guide for the Lost Sector in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Lost Sector" />
        <p>
          Lost Sector is an adventure puzzle mode where the main goal is to kill
          all enemies and find all treasures hidden on the map.
        </p>
        <p>
          When you unlock this mode - after clearing Stage 5-3, only the first
          Sector will be available and you have to clear it and progress further
          in the Campaign to unlock the other Sectors.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/lost_1.jpg"
          alt="Lost Sector"
        />
        <SectionHeader title="Rewards" />
        <StaticImage
          src="../../../images/nikke/generic/lost_2.jpg"
          alt="Lost Sector"
        />
        <p>
          The rewards for each Sector are fixed and to get 100% score, you need
          to gather them all. So it's advised to try to clear the Sectors as
          much as you can the moment you unlock them.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesLostPage;

export const Head: React.FC = () => (
  <Seo
    title="Lost Sector | NIKKE | Prydwen Institute"
    description="Guide for the Lost Sector in Goddess of Victory: NIKKE."
  />
);
